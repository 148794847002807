<template>
    <div>
        <!-- DESKTOP -->
        <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-margin">
            <v-row justify="center">
                <v-col class="pl-0 pr-0 text-left" cols="12">
                    <h1 class="index-margin__title">{{ $translate("titleApp.RecognitionForYou") }}</h1>
                    <p class="subtitle-reconocimientos mt-5">
                        En BRM, cada paso cuenta y cada logro tiene su premio. Si te destacas por tu productividad y
                        satisfacción del cliente, Si tu dedicación y compromiso impactan directamente en la operación,
                        el <strong>Rappi Ranking</strong> te reconoce como un pilar
                        fundamental. Si superas tus objetivos con actitud y energía, el título de <strong>Super
                            Star</strong> está
                        esperando por ti. Y si eres de los que dejan una huella imborrable, solo hay un título que
                        resalta: <strong>Ace of Spades.</strong> ¿Listo para avanzar? ¡El siguiente reconocimiento
                        podría ser el tuyo!
                    </p>
                </v-col>
                <v-col cols="12">
                    <v-row align="center">
                        <template v-for="data in itemCards">
                            <v-col v-if="data.permisson" class="pl-0 pr-6 index-margin__col-card card-reconocimientos col-12"
                                :key="data.id">
                                <v-card outlined elevation="0" class="index-margin__card">
                                    <v-card-title>
                                        <v-layout justify-center>
                                            <img :width="data.width || 150" :height="data.height || 150"
                                                :src="data.icon" style="object-fit: contain;" />

                                        </v-layout>
                                    </v-card-title>
                                    <v-card-text class="pb-0">
                                        <p class="title-margin-action title-card-reconocimientos">{{ data.title }}</p>
                                        <p class="text-selection">{{ data.description }}</p>
                                    </v-card-text>
                                    <v-card-actions class="pt-0 mt-0 mb-3 mt-3">
                                        <v-hover v-slot="{ hover }">
                                            <v-btn @click="go(data)" block rounded style="color: #fff" :style="{
                                                'background-color': hover ? '#324CA1' : '#466BE3',
                                            }" class="pa-xl-0 mt-10">
                                                {{ data.btnName }}
                                            </v-btn>
                                        </v-hover>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <!-- Reemplazar todo el v-dialog por este componente -->
        <CreatingWinners 
            v-model="showFileUpload"
            :token="token"
            @close="showFileUpload = false"
        />

      

        <Alert :open="message.dialog" :text="message.sms" :title="message.title" :type="message.type"
            :redirect="message.redirect" @close="message.dialog = false">
        </Alert>

        <v-overlay :value="loadingAll">
            <v-progress-circular :size="120" color="#466BE3" indeterminate>
                {{ $translate("general.loading") }}
            </v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import Api from "@/utils/api"; // Importa el módulo Api para realizar llamadas a la API
import Alert from "../../components/Alert.vue"; // Importa el componente Alert para mostrar alertas
import CreatingWinners from "./creatingWinners.vue"; // Importar el nuevo componente


export default {
    components: {
        Alert, // Declara el componente Alert para que esté disponible en esta instancia
        CreatingWinners, // Agregar el nuevo componente
        
    },

    data() {
        const currentYear = new Date().getFullYear();  // Año actual
        const previousYear = currentYear - 1;          // Año anterior
        return {
            origin: window.location.origin,
            loadingAll: false,
            showFileUpload: false,
            showUpdateWinners: false,

            token: "", // Guardamos el token
            message: {
                dialog: false,
                title: "",
                sms: "",
                type: "",
                redirect: "",
            },
            infoToken: {
                document: "", // Almacena información del token
            },
            name: "", // Almacena el nombre del usuario
            apps: [], // Lista de aplicaciones
            itemCards: [
                {
                    mobileVersion: true,
                    icon: origin + "/icon/Logo-Super-Stars.svg",
                    title: "Super Stars",
                    redirect: "superStars",
                    description: "Reconocemos a quienes van más allá, alcanzando y superando sus metas mensuales con energía y proactividad.",

                    externalRedirect: false,
                    permisson: false, // si se necesitan o no permisos para ver la tarjeta 
                    btnName: "Continuar",
                    id: 1,
                    id_aplicativo: "659",
                },
                {
                    mobileVersion: true,
                    icon: origin + "/icon/Logo-rappi-ranking.png",
                    title: "Rappi Ranking",
                    description: "Destacamos a quienes marcan la diferencia con su compromiso y aporte en la operación de Rappi.",
                    redirect: "rappiRanking",
                    externalRedirect: false,
                    permisson: false, // si se necesitan o no permisos para ver la tarjeta 
                    btnName: "Continuar",
                    id: 2,
                    id_aplicativo: "660",
                },
                {
                    mobileVersion: true,
                    icon: origin + "/icon/Logo-Ace-Of-Spades.svg",
                    title: "Ace Of Spades",
                    description: "El máximo reconocimiento, para aquellos que dejan una marca duradera en cada desafío.",

                    redirect: "aceOfSpades",
                    externalRedirect: false,
                    permisson: false, // si se necesitan o permisos para ver la tarjeta 
                    btnName: "Continuar",
                    id: 3,
                    id_aplicativo: "661",
                },
                {
                    mobileVersion: true,
                    icon: origin + "/icon/create-ganadores.svg",
                    title: "Crear Ganadores",
                    description: "Módulo para la creación de los ganadores de cada uno de los programas",
                    redirect: "",
                    externalRedirect: false,
                    permisson: false,
                    btnName: "Subir archivo",
                    id: 4,
                    id_aplicativo: "655",
                    width: 150,
                },
                {
                    mobileVersion: true,
                    icon: origin + "/icon/editar-ganadores.svg",
                    title: "Actualizar Ganadores",
                    description: "Módulo para la actualización de los ganadores de cada uno de los programas",
                    redirect: "updatingWinners",
                    externalRedirect: false,
                    permisson: false,
                    btnName: "Continuar",
                    id: 5,
                    id_aplicativo: "778",
                    width: 150,
                },
                {
                    mobileVersion: true,
                    icon: origin + "/icon/eliminar-ganadores.svg",
                    title: "Eliminar Ganadores",
                    description: "Módulo para la eliminación de los ganadores de cada uno de los programas",
                    redirect: "deletingWinners",
                    externalRedirect: false,
                    permisson: false,
                    btnName: "Continuar",
                    id: 6,
                    id_aplicativo: "781",
                    width: 150,
                }
            ],
            name: "Angie", // Nombre predeterminado del usuario
            token: "", // Token de autenticación
            message: {
                dialog: false, // Controla la visibilidad de un diálogo
                title: "", // Título del mensaje
                sms: "", // Mensaje a mostrar
                type: "", // Tipo de mensaje (ej. error, éxito)
                redirect: "", // URL para redirigir después de mostrar el mensaje
            },
        };
    },
    computed: {
        // Computed property para obtener todas las aplicaciones
        getAllApps() {
            let allApps = this.$store.getters.getApps;
            if (allApps.message) {
                this.getApps(allApps.data);
            } else {
                this.loadingAll = true;
            }
        },
    },
    methods: {
        // Método para obtener el token de autenticación
        getToken() {
            var token = localStorage.getItem("token");
            var tokenb64 = localStorage.getItem("tokenB64");
            this.token = tokenb64;
            var div = token.split(".");


            if (div.length >= 2) {
                this.infoToken = JSON.parse(atob(div[1]));
                var nameList = this.infoToken.fullName.split(" ");

                if (nameList.length > 1) {
                    if (nameList[0] != "") {
                        this.name = nameList[0];
                    } else {
                        this.name = nameList[1];
                    }
                } else {
                    this.name = "";
                }
            } else {
                this.infoToken = {
                    document: "",
                };
            }
        },
        // Método para redirigir a una nueva ruta
        go(data) {
            if (data.id === 4) {
                this.showFileUpload = true;
            } else if (data.id === 5) {
                this.$router.push({ 
                    name: 'updatingWinners',
                    params: { token: this.token }
                });
            } else {
                this.$router.push({ name: data.redirect });
            }
        },

        getApps(allApps) {
            if (allApps && allApps.length !== 0) { // Si hay aplicaciones
                let end = allApps.length - 1; // Índice del último elemento

                for (let i = 0; i < allApps.length; i++) {
                    for (let j = 0; j < this.itemCards.length; j++) {
                        // Compara el id_aplicativo con el _ID de las aplicaciones
                        if (this.itemCards[j].id_aplicativo == allApps[i]._ID) {
                            // Asigna permiso si coinciden los IDs
                            this.$set(this.itemCards, j, {
                                ...this.itemCards[j],
                                permisson: true
                            });
                        }
                    }
                    if (i === end) {
                        this.loadingAll = false; // Desactiva la bandera de carga al final del bucle
                    }
                }
            } else {
                this.loadingAll = false; // Si no hay aplicaciones, desactivamos el loader
            }
        },
    },
    // Llamar a getProgramas cuando el componente se monta
    mounted() {
        const currentYear = new Date().getFullYear(); // Año actual
        const previousYear = currentYear - 1; // Año anterior
        // Llenamos yearOptions con el año actual y el anterior
        this.yearOptions = [previousYear, currentYear];
    },
    created() {
        this.getToken(); // Obtiene el token al crear el componente
    },
    watch: {
        // Observador para getAllApps
        getAllApps: function (newCount, oldCount) {
            console.log(""); // Placeholder para el manejo de cambios en getAllApps
        },
    },
};
</script>

<style lang="less" scoped>
@import "./../../assets/css/main.less";




.card-reconocimientos {
    max-width: 280px !important;
}

.card-reconocimientos .index-margin__card ::v-deep .v-card__text {
    height: 100px !important;
}


/* Estilos Santiago*/

.text-selection {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #626262;
    text-align: center !important;
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #626262;
    text-align: center !important;
}

.subtitle-reconocimientos {
    font-size: 20px;
}

.title-card-reconocimientos {
    font-size: 20px;
}

.container-alert-form ::v-deep .v-alert__content {
    text-align: left;
}
</style>
