<template>
    <div>
        <v-dialog v-model="dialogModel" max-width="600px">
            <v-card>
                <v-card-title class="text-h5">Sube el archivo de Excel para crear los ganadores</v-card-title>
                <v-card-text>
                    <v-alert type="info" color="#466be3" class="mb-4 container-alert-form">
                        El archivo debe contener las siguientes columnas:
                        <ul>
                            <li><span>Cédula de los ganadores</span></li>
                        </ul>
                        Asegúrate de que el archivo esté en formato Excel (.xlsx o .xls).
                        <br>
                        <br>
                        <strong>Recomendación:</strong> Descarga la plantilla para subir los ganadores.
                    </v-alert>

                    <v-form ref="fileForm" @submit.prevent="uploadFile" v-model="formValid">
                        <v-select v-model="selectedProgram" :items="programOptions" label="Selecciona el programa"
                            item-text="nombre" item-value="id" outlined required
                            :rules="[v => !!v || 'Por favor selecciona un programa']" @input="handleProgramChange">
                        </v-select>

                        <v-select v-model="selectedRecurrence" :items="recurrenceOptions"
                            label="Selecciona la recurrencia" item-text="nombre" item-value="id" outlined required
                            :rules="[v => !!v || 'Por favor selecciona una recurrencia']">
                        </v-select>

                        <v-select v-model="selectedYear" :items="yearOptions" label="Selecciona el año" outlined
                            required :rules="[v => !!v || 'Por favor selecciona un año']"></v-select>

                        <v-file-input v-model="file" label="Selecciona un archivo Excel" accept=".xlsx,.xls" outlined
                            required hint="El archivo debe estar en formato Excel"
                            :rules="[v => !!v || 'Por favor selecciona un archivo']"></v-file-input>

                        <v-btn :disabled="loadingAll || !file" color="primary" block type="submit" class="mt-4"
                            background-color="#466be3">
                            Subir archivo
                        </v-btn>
                    </v-form>

                    <v-btn color="primary" @click="getPlantilla" class="mt-4" block>
                        Descargar plantilla
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <Alert 
            :open="message.dialog" 
            :text="message.sms" 
            :title="message.title" 
            :type="message.type"
            :redirect="message.redirect" 
            @close="message.dialog = false"
        />
    </div>
</template>

<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";

export default {
    name: 'CreatingWinners',
    components: {
        Alert,
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        token: {
            type: String,
            required: true
        }
    },
    data() {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;
        return {
            loadingAll: false,
            file: null,
            programOptions: [],
            recurrenceOptions: [],
            selectedProgram: null,
            selectedRecurrence: null,
            programData: null,
            selectedYear: null,
            yearOptions: [currentYear, previousYear],
            formValid: false,
            message: {
                dialog: false,
                title: "",
                sms: "",
                type: "",
                redirect: "",
            }
        };
    },
    computed: {
        dialogModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        // Método para obtener los programas
        getProgramas() {
            Api.Reconocimientos()
                .getProgramas(this.token)
                .then((res) => res.json())
                .then((response) => {
                    // Guarda toda la respuesta en orden inverso
                    this.programOptions = response.data.reverse(); // Invierte el orden de los programas
                    if (this.programOptions.length > 0) {
                        this.updateRecurrenceOptions(); // Llama a actualizar las opciones de recurrencia
                    }
                })
                .catch((error) => {
                    console.error('Error al obtener programas:', error);
                });
        },

        // Esta función se ejecuta cuando se cambia el programa seleccionado
        handleProgramChange() {
            this.updateRecurrenceOptions(); // Actualiza las opciones de recurrencia
        },

        // Función para actualizar las opciones de recurrencia
        updateRecurrenceOptions() {
            if (this.selectedProgram && this.programOptions) {
                // Busca la recurrencia del programa seleccionado dentro de la respuesta completa
                const selectedProgramData = this.programOptions.find(program => program.id === this.selectedProgram);
                if (selectedProgramData && selectedProgramData.recurrencia) {
                    this.recurrenceOptions = selectedProgramData.recurrencia.periodos;
                    // Resetea la recurrencia seleccionada
                    this.selectedRecurrence = null; // Resetea la recurrencia al cambiar de programa
                }
            }
        },

        uploadFile() {
            const formData = new FormData();
            formData.append('programa_id', this.selectedProgram);
            formData.append('periodo_id', this.selectedRecurrence);
            formData.append('vigencia', this.selectedYear);
            formData.append('ganadores', this.file);

            this.loadingAll = true;

            const timeoutPromise = new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Timeout excedido')), 30000)
            );

            const apiPromise = Api.Reconocimientos()
                .registrarGanadores(this.token, formData)
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else {
                        throw new Error('Error en la respuesta del servidor');
                    }
                });

            Promise.race([apiPromise, timeoutPromise])
                .then((response) => {
                    let successMessage = "";
                    let errorMessages = [];

                    if (response && response.data) {
                        if (response.data.creados > 0) {
                            successMessage = `Se han registrado ${response.data.creados} ganadores correctamente.`;
                        }

                        if (response.data.errores && response.data.errores.length > 0) {
                            let cedulasConError = [];
                            for (let error of response.data.errores) {
                                for (let cedula in error) {
                                    cedulasConError.push(`${cedula}: ${error[cedula]}`);
                                }
                            }

                            if (cedulasConError.length > 0) {
                                errorMessages.push(`Los siguientes usuarios no fueron creados:\n${cedulasConError.join("\n")}`);
                            }
                        }
                    }

                    if (successMessage && errorMessages.length > 0) {
                        this.showAlert("Algunos ganadores fueron registrados, otros no:", 
                            `${successMessage}\n\n${errorMessages.join("\n\n")}`, "warning");
                    } else if (successMessage) {
                        this.showAlert("Ganadores registrados", successMessage, "success");
                    } else if (errorMessages.length > 0) {
                        this.showAlert("Errores al registrar ganadores", 
                            errorMessages.join("\n\n"), "error");
                    }
                })
                .catch((error) => {
                    if (error.message === 'Timeout excedido') {
                        this.showAlert("Timeout", 
                            "La solicitud ha tardado demasiado tiempo. Por favor, inténtelo de nuevo más tarde.", 
                            "error");
                    } else {
                        this.showAlert("Error al registrar ganadores", 
                            error.message || "Ocurrió un error al registrar los ganadores. Por favor, intenta de nuevo.", 
                            "error");
                    }
                })
                .finally(() => {
                    this.loadingAll = false;
                    this.clearForm();
                });
        },

        clearForm() {
            // Limpiar las variables de los campos
            this.selectedProgram = null;
            this.selectedRecurrence = null;
            this.selectedYear = null;
            this.file = null;

            // Cerrar el diálogo
            this.$emit('close');

            // Restablecer la validación del formulario
            this.$nextTick(() => {
                if (this.$refs.fileForm) {
                    this.$refs.fileForm.reset();
                }
            });
        },

        getPlantilla() {
            Api.Reconocimientos()
                .getPlantilla(this.token)
                .then((res) => {
                    if (res.ok) {
                        return res.blob();
                    }
                    throw new Error('No se pudo obtener la plantilla');
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'plantilla.xlsx';
                    link.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error('Error al obtener plantilla:', error);
                });
        },

        showAlert(title, message, type) {
            this.message.dialog = true;
            this.message.title = title;
            this.message.sms = message;
            this.message.type = type;
        },
    },
    mounted() {
        this.getProgramas();
    }
};
</script>

<style lang="less" scoped>
.container-alert-form ::v-deep .v-alert__content {
    text-align: left;
}
</style>
